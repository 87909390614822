.login-page input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6cfd9;
  border-radius: 8px;
  height: 52px;
  color: #000;
  width: 100% !important;
}
.login-page input {
  padding: 0 10px !important;
}
