.profileImage {
  width: 30px;
  height: 30px;
}

.profileImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imagesize .dropdown-toggle {
  display: flex;
  align-items: center;
}
