.forget-page .btn-success {
  background-color: #1d5ebe;
  color: #fff;
  border: 1px solid #1d5ebe;
  border-radius: 26px;
  height: 45px;
  width: 180px;
  white-space: nowrap;
  padding: 0 !important;
  position: relative;
  /* padding-left: 50px !important; */
  text-align: center;
}
/* .forget-page .btn-success:hover {
  border: 1px solid #0f2f5f;
  background-color: #0f2f5f;
  color: #fff;
  /* padding-left: 20px !important; */
 
.forget-page .btn-success .user-logo-img {
  margin-right: 12px;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 25px;
  transition: ease all 1s;
}
/* .forget-page .btn-success:hover .user-logo-img {
  left: 140px;
} */

@media (max-width: 479.98px) {
  .bottom-buttons {
    flex-direction: column;
  }
}

@media (max-width: 319.98px) {
}
