@font-face {
  font-family: "Assistant";
  src: url("../fonts/Assistant-Bold.woff2") format("woff2"),
    url("../fonts/Assistant-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Assistant";
  src: url("../fonts/Assistant-SemiBold.woff2") format("woff2"),
    url("../fonts/Assistant-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Assistant";
  src: url("../fonts/Assistant-Medium.woff2") format("woff2"),
    url("../fonts/Assistant-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Assistant";
  src: url("../fonts/Assistant-Regular.woff2") format("woff2"),
    url("../fonts/Assistant-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Assistant";
  src: url("../fonts/Assistant-Light.woff2") format("woff2"),
    url("../fonts/Assistant-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Assistant" !important;
  font-size: 18px;
  font-weight: 400;
}
h1 {
  font-size: 90px;
  line-height: 118px;
}
h2 {
  font-size: 56px;
  line-height: 73px;
}
h3 {
  font-size: 32px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}
.wrapper {
  overflow: hidden;
}
.container-width {
  max-width: 1660px;
  margin: 0 auto;
}
/* header one */
.header-one {
  background-color: #0f2f5f;
  padding: 0px 0px;
}
.header-one .contact-icons {
  position: relative;
  padding-left: 30px;
  text-decoration: none;
}
.header-one .contact-icons .head-icons {
  font-size: 18px;
  color: white;
  width: 25px;
  position: absolute;
  left: 0;
}
.header-one .contact-icons h6 {
  font-size: 16px;
  color: white;
  font-weight: normal;
  margin-left: 0;
}
.header-one .contact-icons-margin {
  margin-left: 55px;
}
.header-one .header-one-social-icons a {
  margin: 0px 16px;
  transition: ease all 0.5s;
}
.header-one .header-one-social-icons a:hover {
  transform: scale(1.2);
}
.header-one .header-one-social-icons .head-social {
  color: white;
  font-size: 20px;
}
.header-one .header-one-social-icons .head-social:first-child {
  margin-left: 0px;
}
.header-one .header-one-social-icons .head-social:last-child {
  margin-right: 0px;
}
.header .header-one .header-one-area-content a {
  text-decoration: none;
}
.similar-company {
  color: #000;
  text-decoration: none;
}
.similar-company:hover {
  color: #0a58ca;
}
/* header two */
.header-two {
  padding: 15px 0px;
}
/* header three */
.header-three {
  background-color: #0f3264;
}
.header-three .header-list .nav-item .nav-link {
  position: relative;
  z-index: 9;
  padding: 10px 25px;
  background-color: #0f3264;
  border-top: 2px solid #0f3264;
  min-width: 140px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.select-design {
  /* appearance: none; */
  outline: 0;
  box-shadow: none;
  border: 1px solid #dcdcdc !important;
  background: #ffffff;
  background-image: none;
  flex: 1 1;
  padding: 0 0.5em;
  color: #000;
  cursor: pointer;
  font-size: 0.8em;
  font-family: "Open Sans", sans-serif;
  position: relative;
  border-radius: 8px;
}
.select-design::-ms-expand {
  display: none;
}
.header-list .nav-item .icon-holder {
  width: 200px;
  position: absolute;
  top: -20px;
  z-index: -1;
  left: 0;
  display: none;
}
.header-three .header-list .nav-link {
  font-weight: 600;
  color: white !important;
  font-size: 18px;
  /* padding: 0; */
}
.removeBlur {
  backdrop-filter: blur(3px) !important;
  padding: 16px !important;
  width: 200px !important;
  text-decoration: none !important;
}
.header-three .header-list .nav-item .nav-link:hover,
.header-three .header-list .nav-item .nav-link.active {
  background-color: #1d5ebe;
  border-color: #ffd046;
}
.header-list .nav-item .nav-link.active .icon-holder,
.header-list .nav-item .nav-link:hover .icon-holder {
  display: block;
}
.header-three .header-btn {
  border-radius: 23px;
  height: 45px;
  width: 128px;
  font-weight: 600;
  font-size: 18px;
}
.header-three .bg-yellow {
  background-color: #ffd046;
  border: 1px solid #ffd046;
}
.header-three .header-btn {
  position: relative;
  padding-left: 50px;
  transition: ease all 1s;
}
.header-three .header-btn:hover {
  padding-left: 10px;
  background: #123a75 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  color: #fff;
}
.header-three .bg-blue {
  background-color: #1d5ebe;
  border: 1px solid #1d5ebe;
  margin-left: 9px;
  color: #fff;
}
.header-three .user-logo-img {
  margin-right: 12px;
  height: 32px;
  width: 32px;
  position: absolute;
  left: 5px;
  transition: ease all 1s;
}
.header-three .user-logo-img img {
  width: 100%;
  height: 100%;
}
.header-three .bg-yellow .user-logo-img img.hover {
  display: none;
}
.header-three .bg-yellow:hover .user-logo-img img {
  display: none;
}
.header-three .bg-yellow:hover .user-logo-img img.hover {
  display: block;
}
.header-three .header-btn:hover .user-logo-img {
  left: 90px;
}
/* main */
.main {
  background-image: url(../Images/main-bg.png);
  background-repeat: no-repeat;
  height: auto;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-bottom: 350px;
}
.main .main-content {
  padding: 76px 0px;
}
.main .main-content h4 {
  color: white;
  font-weight: normal;
  font-size: 36px;
}
.main .main-content .yellow,
.latest-news .latest-news-content-text .yellow {
  color: #ffd046;
}
.main .main-content h1 {
  color: white;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  margin-bottom: 15px;
}
.main .main-content p {
  color: white;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}
.main .main-content .btn-transparent {
  border-radius: 30px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  height: 55px;
  width: 200px;
  font-weight: 600;
  font-size: 18px;
}
.main .main-content-input-wrapper,
.table-section .table-section-area-form-content .main-content-input-wrapper {
  position: relative;
  width: 92%;
  border-radius: 30px;
  height: 60px;
  margin-bottom: 40px;
}
.main .main-content-input-wrapper .search-icon,
.table-section
  .table-section-area-form-content
  .main-content-input-wrapper
  .search-icon {
  position: absolute;
  left: 0;
  font-size: 25px;
  width: 60px;
}
.main .main-content-input-wrapper input,
.table-section
  .table-section-area-form-content
  .main-content-input-wrapper
  input {
  width: 100%;
  height: 100%;
  padding-left: 55px;
  border-radius: 30px;
  border: 1px solid #eaeaea;
}
.main .main-content-input-wrapper input:focus,
.table-section
  .table-section-area-form-content
  .main-content-input-wrapper
  input:focus {
  border: 1px solid #eaeaea;
  box-shadow: unset;
}
.main .main-content-input-wrapper .btn-blue,
.table-section
  .table-section-area-form-content
  .main-content-input-wrapper
  .btn-blue {
  position: absolute;
  right: 10px;
  border-radius: 30px;
  background-color: #0f2f5f;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid;
  height: 77%;
  width: 120px;
}
.main .builder-content h4 {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  color: #ffd046;
}
.main .row-padding {
  padding-left: 80px;
}
.main .builder-col-head {
  width: 195px;
}
.main .builder-col-info {
  width: calc(100% - 195px);
}
.main .builder-content.content-border {
  position: relative;
  padding-top: 25px;
}
.main .builder-content.content-border::before {
  content: "";
  position: absolute;
  background: transparent
    linear-gradient(180deg, #ffffff00 0%, #ffffff 48%, #ffffff00 100%);
  height: 90px;
  width: 3px;
  top: 0;
  right: 0;
}
.main .builder-content p {
  font-size: 18px;
  line-height: 30px;
  color: white;
  font-weight: normal;
  margin-bottom: 20px;
}
.main .builder-content a {
  font-size: 20px;
  line-height: 26px;
  color: #ffd046;
  font-weight: bold;
  text-decoration: none;
}
.main .builder-content a:hover {
  border-bottom: 1px solid white;
  color: white;
}
.content-padding {
  padding-left: 45px;
}
/* global coverage */
.global-coverage {
  margin-top: -350px;
}

.global-coverage .global-coverage-images img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* key values */
.section-padding {
  padding: 30px 0px;
}
.key-values {
  background-image: url("../Images/key-values-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
}
.key-values .key-values-info-area {
  width: 1208px;
  margin: 0 auto 85px;
}
.key-values .key-values-info-area h4,
.latest-news-info-area h4 {
  color: #575757;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 6.92px;
}
.key-values .key-values-info-area h2,
.latest-news-info-area h2,
.how-it-work .how-it-work-content h2 {
  color: #000000;
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 22px;
  line-height: 65px;
}
.key-values .images-border-wrapper {
  position: relative;
  width: 630px;
  margin: 0 auto 45px;
}
.key-values .images-border-wrapper::after {
  content: "";
  position: absolute;
  border-top: 1px solid;
  width: 260px;
  color: #0000004f;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.key-values .images-border-wrapper::before {
  content: "";
  position: absolute;
  border-top: 1px solid;
  width: 260px;
  color: #0000004f;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.key-values .key-values-info-area p {
  color: #000000b8;
  font-size: 22px;
  font-weight: normal;
}
.key-values .key-values-area h3,
.key-values .key-values-graph-area h3 {
  color: #000000;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 25px;
}
.key-values .key-values-area-content {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.key-values .key-values-area-content ul {
  height: 325px;
  overflow-y: scroll;
  padding-right: 15px !important;
}
.key-values .key-values-area-content .ul-height {
  height: 255px;
}
.key-values .key-values-area-content ul li {
  list-style: none;
  padding: 12px 0px;
  border-bottom: 1px dashed #c1c1c1;
}
.key-values .key-values-area-content ul li:last-child {
  border-bottom: none;
}
.key-values .key-values-area-content ul li h5 {
  font-size: 18px;
  font-weight: normal;
  color: black;
  margin: 0px;
}
.key-values .key-values-area-content ul li h6 {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin: 0px;
}
/* key values => scroll bar */
/* width */
.key-values .key-values-area-content ul::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
  height: 4px;
}

/* Track */
.key-values .key-values-area-content ul::-webkit-scrollbar-track {
  background: #313132;
}

/* Handle */
.key-values .key-values-area-content ul::-webkit-scrollbar-thumb {
  background: #0f3264;
}

/* Handle on hover */
.key-values .key-values-area-content ul::-webkit-scrollbar-thumb:hover {
  background: #0f3264;
}

.vertical-bar-chart .horizontal-chart::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
  height: 8px;
  background-color: red;
}

/* Track */
.vertical-bar-chart .horizontal-chart::-webkit-scrollbar-track {
  background: #313132;
}

/* Handle */
.vertical-bar-chart .horizontal-chart::-webkit-scrollbar-thumb {
  background: #0f3264;
}

/* Handle on hover */
.vertical-bar-chart .horizontal-chart::-webkit-scrollbar-thumb:hover {
  background: #0f3264;
}
html::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
}

html::-webkit-scrollbar-button {
  background-color: #313132;
}

html::-webkit-scrollbar-track {
  background-color: #fff;
}

html::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

html::-webkit-scrollbar-thumb {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #0d4666 0%, #000d39 100%) !important;
}

html::-webkit-scrollbar-corner {
  background-color: #fff;
}

html::-webkit-resizer {
  background-color: #fff;
}

html {
  scrollbar-color: #0f3264 #fff;
  scrollbar-width: thin;
}
/* graph */
.key-values .key-values-graph-area {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #0d4666 0%, #000d39 100%);
  border-radius: 5px;
  margin-top: 63px;
  padding: 40px 0 40px 0px;
  height: 610px !important;
  /* overflow-y: auto; */
  overflow-y: hidden;
  overflow-x: auto;
}
.key-values .key-values-graph {
  margin-bottom: 38px;
}
.key-values .key-values-graph-area h3 {
  color: white;
}
.key-values .key-values-graph-area ul li {
  list-style: none;
  font-size: 18px;
  color: white;
  font-weight: bold;
  line-height: 38px;
  position: relative;
  padding-left: 24px;
}
.my-spin {
  animation: fa-spin 2s infinite linear;
}
.my-spinner {
  display: inline-block;
  font: normal normal normal 14px/1;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.loader {
  width: 100%;
  position: absolute;
  background: #00000040;
  height: 650px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 50px;
  z-index: 100;
}
.helm-modal .modal-footer button {
  min-width: 100px;
}
.helm-modal .modal-footer .btn-secondary {
  padding: 10px 25px !important;
}
.loader-props {
  width: 100%;
  position: fixed;
  background: #00000040;
  /* height: 650px; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 50px;
  z-index: 100;
}
.contactUsPosition {
  position: relative;
}
.contactUsPositionWidth {
  width: 100% !important;
}
.contactUsRowInfo {
  padding: 0% !important;
}
.key-values .key-values-graph-area ul li::before {
  position: absolute;
  content: "";
  border-left: 4px solid;
  color: #8ec536;
  height: 18px;
  border-radius: 10px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.key-values .key-values-graph-area ul .orange-border::before {
  color: #ffd046;
}
.key-values .key-values-graph-area ul .blue-border::before {
  color: #93d3d4;
}
.key-values .key-values-graph-area ul .red-border::before {
  color: #f1625c;
}
/* country graph */
.country-graph-wrapper-info {
  width: 53% !important;
}
.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.flag-information-wrapper {
  padding-top: 0;
  height: calc(100% - 80px);
  overflow-y: auto;
  padding-right: 10px;
  position: relative;
  z-index: 1;
}
.flag-information-wrapper::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
}
.flag-information-wrapper::-webkit-scrollbar-button {
  background-color: #313132;
}
.flag-information-wrapper::-webkit-scrollbar-track {
  background-color: #fff;
}
.flag-information-wrapper::-webkit-scrollbar-track-piece {
  background-color: #313132;
}
.flag-information-wrapper::-webkit-scrollbar-thumb {
  background: #0f3264 !important;
}
.flag-information-wrapper::-webkit-scrollbar-corner {
  background-color: #fff;
}
.flag-information-wrapper::-webkit-resizer {
  background-color: #fff;
}
.flag-information-wrapper {
  scrollbar-color: #0f3264 #000;
  scrollbar-width: thin;
}

.echarts-for-react::-webkit-scrollbar {
  width: 100px;
  border-radius: 10px;
  height: 4px;
}

/* Track */
.echarts-for-react::-webkit-scrollbar-track {
  background: #b9b9b9;
}

/* Handle */
.echarts-for-react::-webkit-scrollbar-thumb {
  background: #123a75;
}

.country-graph-wrapper {
  margin-bottom: 22px;
}
.country-graph-wrapper .country-graph-wrapper-info p {
  font-size: 14px;
  color: white;
  font-weight: normal;
  line-height: 21px;
  text-align: right;
  width: calc(100% - 42px);
}
.progress-wrapper {
  height: 25px;
  width: 70%;
}
.progress-wrapper-bg {
  background-color: #ffd046;
  border-radius: 23px;
  height: 100%;
  padding: 0 8px 0 20px;
  min-width: 65px;
}
.country-flag-img {
  padding-right: 0px;
  width: 35px;
  margin-right: 15px;
  margin-left: 20px;
  height: 25px;
  margin-bottom: 3px;
}
#vesselTypeChart {
  height: 490px !important;
  margin: 64px 0 0;
  width: 100% !important;
}
.echarts-for-react {
  height: 470px !important;
  min-width: 600px;
}
.country-graph-wrapper .progress-wrapper-bg p {
  color: black;
  font-weight: bold;
  font-size: 16px;
}
/* target market */
.target-market {
  background-color: #f5f5f6;
}
.target-market .target-market-content h3,
.how-it-work .how-it-work-content h3 {
  font-size: 32px;
  line-height: 42px;
  font-weight: bold;
  color: black;
  margin-bottom: 30px;
}
.target-market .target-market-content p,
.how-it-work .how-it-work-content p {
  font-size: 24px;
  line-height: 32px;
  color: #000000b8;
  font-weight: normal;
  margin-bottom: 60px;
}
.target-market .target-btn,
.how-it-work .how-it-work-btn {
  position: relative;
  padding-left: 50px;
  transition: ease all 1s;
  border-radius: 23px;
  height: 45px;
  width: 170px;
  font-weight: 600;
  font-size: 18px;
}
.target-market .bg-blue,
.how-it-work .bg-blue {
  background-color: #1d5ebe;
  border: 1px solid #1d5ebe;
  color: white;
}
.target-market .user-logo-img,
.how-it-work .user-logo-img {
  margin-right: 12px;
  height: 32px;
  width: 32px;
  position: absolute;
  left: 5px;
  transition: ease all 1s;
}
.target-market .target-btn:hover,
.how-it-work .how-it-work-btn:hover {
  padding-left: 10px;
  background: #123a75 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  color: #fff;
}
.target-market .target-btn:hover .user-logo-img,
.how-it-work .how-it-work-btn:hover .user-logo-img {
  left: 77%;
}
.target-market ul li {
  color: #000000b8;
  font-size: 18px;
  line-height: 31px;
  font-weight: normal;
  list-style: none;
}
.target-market .tick-image,
.how-it-work .tick-image {
  margin-right: 10px;
  width: 25px;
}
.li-wrapper {
  width: calc(100% - 25px);
}
/* latest news */
.latest-news {
  background-color: #f5f5f6;
  position: relative;
}
.latest-news .latest-news-info-area h4,
.latest-news .latest-news-info-area h2 {
  color: black;
}
.latest-news .latest-news-content {
  cursor: pointer;
}
.latest-news .latest-news-content-img {
  margin-bottom: 30px;
  overflow: hidden;
}
.latest-news .latest-news-content-img img {
  transition: ease all 0.5s;
}
.latest-news .latest-news-content:hover .latest-news-content-img img {
  transform: scale(1.2);
}
.latest-news .latest-news-content-text {
  padding-right: 100px;
  position: relative;
  padding-left: 25px;
}
.latest-news .latest-news-content-text::before {
  position: absolute;
  content: "";
  border-left: 3px solid #5567a3a8;
  border-radius: 10px;
  left: 0;
  top: 50%;
  transform: translatey(-50%);
  height: 100%;
  transition: ease all 0.5s;
}
.latest-news .latest-news-content:hover .latest-news-content-text::before {
  border-left: 3px solid #5567a3;
}
.latest-news .latest-news-content-text h4 {
  font-size: 22px;
  font-weight: bold;
  color: black;
  margin-bottom: 15px;
  line-height: 30px;
}
.latest-news .latest-news-content-text h6 {
  font-size: 16px;
  font-weight: normal;
  color: #000d39;
}
.latest-news .load-more-wrapper {
  text-decoration: none;
  position: absolute;
  width: 100%;
  height: 600px;
  background: transparent linear-gradient(180deg, #000d3905 0%, white 100%) 0%
    0% no-repeat padding-box;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 50px;
  flex-direction: column;
  left: 0;
}
.latest-news .load-more-wrapper span {
  color: #0f2f5f;
  font-size: 14px;
  font-weight: normal;
  display: block;
}
/* how it work */
.how-it-work .how-it-work-content h3 {
  padding-right: 100px;
}
.how-it-work .how-it-work-content p {
  margin-bottom: 25px;
}
.how-it-work .how-it-work-content strong {
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  display: block;
  color: black;
  margin-bottom: 20px;
}

/* table-setion */
.table-section {
  background-color: #f7f7fa;
  padding-bottom: 150px;
}
.table-section .table-section-area-form-content {
  margin-bottom: 30px;
}
.table-section .table-section-area {
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 40px;
}
.table-section .table-section-area-form-content .main-content-input-wrapper {
  width: 58%;
}
.table-section
  .table-section-area-form-content
  .main-content-input-wrapper
  input {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
}
.table-section .table-section-area-form-content h5 {
  font-size: 18px;
  font-weight: normal;
  color: #000000;
}
.table-section .bold {
  font-weight: bold;
}
.table-section table {
  border-collapse: separate;
  border-spacing: 0px 10px;
}
.table-section table tr th {
  color: #171c3866;
  white-space: nowrap;
}
.table-section .table > :not(caption) > * > * {
  padding: 15px;
}
.table-section tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: unset;
}
.table-section table tr td {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  white-space: nowrap;
}
.table-section table tr td:first-child {
  border-left: 1px solid #ececec;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table-section table tr td:last-child {
  border-right: 1px solid #ececec;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* subscription */
.subscription {
  padding: 60px 0px;
}
.subscription .subscription-area {
  background-color: #0f2f5f;
  border-radius: 11px;
  padding: 40px;
  position: relative;
  padding-left: 180px;
  /* top: -80px; */
}
.subscription .subscription-area .subscription-area-img {
  position: absolute;
  left: 20px;
  width: 150px;
  top: 0;
}
.subscription .subscription-area-img .icon-holder.search-icon {
  width: 100%;
}
.subscription .subscription-area-content h4 {
  color: white;
}
.subscription .subscription-area-content p {
  color: white;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}
.subscription .subscription-form input {
  border-radius: 30px;
  margin: 0px 9px;
  height: 60px;
  width: 400px;
  padding-left: 30px;
}
.subscription .subscription-form input::placeholder {
  font-size: 16px;
  font-weight: normal;
  color: black;
}
.subscription .subscription-form input:focus {
  box-shadow: unset;
  border: 1px solid white;
}
.subscription .subscription-btn {
  font-weight: 600;
  font-size: 18px;
  color: white;
  background-color: #0f2f5f;
  border-radius: 30px;
  border: 7px solid white;
  height: 60px;
  width: 140px;
}

/* ========= zzzz */

.banner .banner-content {
  position: relative;
}
.banner .banner-heading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-heading h1 {
  color: #fff;
}
.sea-waves-bg {
  padding-top: 75px;
  padding-bottom: 30px;
}
.sea-waves-content {
  padding-top: 0px;
}
.sea-waves-content h2 {
  margin-bottom: 42px;
}
.sea-waves-content p {
  font-size: 22px;
  line-height: 28px;
  color: #000000b8;
}
.sea-waves-content p:nth-child(2) {
  margin-bottom: 42px;
}
.sea-waves-img-info {
  position: relative;
}
.sea-waves-img-info p {
  font-size: 22px;
  line-height: 36px;
}
.sea-waves-info {
  max-width: 623px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 50px #00000014;
  padding: 50px;
  position: absolute;
  bottom: -130px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.footer {
  text-align: -webkit-auto;
  background-color: #f7f7fa;
  padding-top: 68px;
  position: relative;
  z-index: 9;
}
.footer::before {
  content: "";
  background-image: url(../Images/footer-bg.png);
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: top left;
  top: 0;
}
.footer .footer-logo {
  margin-bottom: 42px;
}
.footer p {
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 42px;
}
.footer .read-more {
  color: #123a75;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}
.footer-link p {
  font-size: 20px;
  line-height: 26px;
  color: #090000;
  margin-bottom: 20px;
  font-weight: bold;
}
.footer-link-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.close-height {
  height: 46px;
}
.footer-link-ul a {
  text-decoration: none;
  font-size: 18px;
  color: #090000;
  line-height: 40px;
}
.footer-link-ul a:hover {
  color: #123a75;
}
.onHover:hover {
  color: #123a75;
}
.footer-link-ul span {
  text-decoration: none;
  font-size: 18px;
  color: #090000;
  line-height: 40px;
  cursor: pointer;
}
.footer-link-ul span:hover {
  color: #123a75;
}
.footer-link:nth-child(1) {
  margin-bottom: 30px;
}
.footer-links-container {
  padding-top: 60px;
}
.footer-link-ul .fa {
  color: #123a75;
  width: 18px;
}
.social-links ul {
  padding: 0;
}
.social-links li {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.social-links li a:hover {
  transform: scale(1.2);
}
.social-links li .fa-facebook,
.social-links li .fa-facebook-f {
  color: #2e5eb5;
}

.social-links li .fa-google-plus,
.social-links li .fa-google-plus-g {
  color: #cc0000;
}

.social-links li .fa-twitter,
.social-links li .fa-twitter {
  color: #1db0bd;
}

.social-links li .fa-linkedin,
.social-links li fa-linkedin-in {
  color: #2b5094;
}
.footer-link-ul svg {
  color: #123a75;
  width: 18px;
}
.vessels-port {
  padding-top: 180px;
}
.vessels-port h3 {
  margin-bottom: 35px;
}

.accordion-container {
  border: 1px solid #c1c1c1;
  padding: 14px 24px;
  border-radius: 5.5px;
  height: calc(100% - 70px);
}
.copyright {
  background-color: #0f2f5f;
  margin-top: 50px;
}
.copyright p {
  color: #fff;
  font-size: 16px;
  padding: 15px 0;
}
.copyright ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.copyright ul a {
  text-decoration: none;
  color: #ffd046;
}
.copyright ul li {
  margin-right: 7px;
}

.vessels-port .accordion-header {
  line-height: 0;
}
.vessels-port .accordion-header .accordion-button.collapsed {
  border: 1px solid #ececec;
  font-size: 18px;
}
.vessels-port .accordion-header .accordion-button {
  border: 1px solid #ececec;
  border-bottom: 0;
}
.vessels-port .accordion-item .accordion-collapse.show .accordion-body {
  border-top: 0;
}
.vessels-port .accordion-item {
  margin-bottom: 10px;
  border: 0;
}
.vessels-port .accordion-table p {
  margin-bottom: 0;
  padding: 0;
  font-size: 18px;
  word-break: break-word;
}
.vessels-port .accordion-item .accordion-body {
  border: 1px solid #ececec;
}

.vessels-port .accordion-table .row1 p:nth-child(1) {
  width: 50%;
}
.vessels-port .accordion-table .row1 p:nth-child(2) {
  font-weight: bold;
  width: 50%;
}
.vessels-port .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
}
.vessels-port .accordion-button:focus {
  box-shadow: none;
}
.vessels-port .ports-container {
  padding: 24px 19px;
  border-radius: 5px;
  border: 1px solid #c1c1c1;
  margin-bottom: 55px;
}
.ports-container .port {
  background-color: #f7f7fa;
  padding: 15px 10px 15px 6px;
  margin: 0 15px 15px 0;
  border: 1px solid #f7f7fa;
}
.ports-container .port:hover {
  background-color: transparent;
  cursor: pointer;
}
.ports-container .port .flag-name p {
  font-size: 18px;
  color: #000000;
  white-space: nowrap;
}
.ports-container .port .date p {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
}
.vessels-port .marginb {
  margin-bottom: 80px;
}
.bg-content-desp {
  height: 100%;
  background-color: #0f2f5f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-content-desp p {
  color: #ffffff;
  font-size: 22px;
  line-height: 37px;
  text-align: center;
  padding: 10px 110px;
}
.bg-content-desp p span {
  font-size: 32px;
}
/* updated css */
.content-table-bg {
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 30px !important;
}
.build-table-bg {
  background-color: #f7f7fa;
}
.build-table .build-table-bg .row1 {
  background-color: white;
  padding: 12px;
  margin-bottom: 12px;
}
.build-table h5 {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 20px;
}
.build-table p {
  font-weight: bold !important;
}
.sea-small-images img {
  width: 100%;
}
.ports-container {
  border: 1px solid #c1c1c1;
  padding: 30px;
  border-radius: 10px;
}
.vesseles-last-info-content p {
  font-size: 22px;
  font-weight: normal;
  line-height: 28px;
}
.google-map-wrapper {
  padding-top: 30px;
}
/* === vessels-listing ========= */
.vessels-database {
  background-color: #f7f7fa;
  padding: 15px 0;
}
.vessels-database h2 {
  margin-bottom: 50px;
  color: #000000;
  /* font-size: 45px;
  line-height: 65px; */
}
.vessels-database .input-fields .form-control {
  padding: 18px 22px;
  color: #000;
  font-size: 18px;
  line-height: 23px;
  border: 1px solid #b7b3b3;
  border-radius: 7px;
}
.vessels-database .input-fields .form-control:focus,
.vessels-database .input-fields .dropdown button {
  box-shadow: unset;
}
.vessels-database .input-fields .dropdown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 22px;
  border: 1px solid #b7b3b3;
  background-color: #eaeaea8f;
  color: #b7b3b3;
}
.vessels-database .input-fields .all-flags button {
  background-color: transparent;
}
.vessels-database .input-fields {
  margin-bottom: 70px;
}

.r-slider1 .range-slider {
  position: relative;
  width: 100%;
  height: 42px;
  text-align: center;
}

.r-slider1 .range-slider input {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: -4px;
  width: 100%;
  outline: none;
  height: 50px;
  margin: 0;
  padding: 0;
}

.r-slider1 .range-slider input::-webkit-slider-thumb {
  pointer-events: all;
  position: relative;
  z-index: 1;
  outline: 0;
}

.r-slider1 .range-slider input::-moz-range-thumb {
  pointer-events: all;
  position: relative;
  z-index: 10;
  -moz-appearance: none;
  width: 9px;
}

.r-slider1 .range-slider input::-moz-range-track {
  position: relative;
  z-index: -1;
  background-color: rgba(0, 0, 0, 1);
  border: 0;
}

.r-slider1 .range-slider input:last-of-type::-moz-range-track {
  -moz-appearance: none;
  background: none transparent;
  border: 0;
}

.r-slider1 .range-slider input[type="range"]::-moz-focus-outer {
  border: 0;
}

.r-slider1 .rangeValue {
  width: 30px;
}

.r-slider1 .output {
  position: absolute;
  border: 1px solid #999;
  /* width: 40px; */
  width: 65px;
  height: 30px;
  text-align: center;
  color: #999;
  border-radius: 4px;
  display: inline-block;
  font: bold 15px/30px Helvetica, Arial;
  bottom: 75%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 15pxs;
}

.r-slider1 .output.outputTwo {
  left: 100%;
}

.r-slider1 input[type="range"] {
  -webkit-appearance: none;
  background: none;
}

.r-slider1 input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  border: none;
  border-radius: 3px;
  background: transparent;
}

.r-slider1 input[type="range"]::-ms-track {
  height: 5px;
  background: transparent;
  border: none;
  border-radius: 3px;
}

.r-slider1 input[type="range"]::-moz-range-track {
  height: 5px;
  background: transparent;
  border: none;
  border-radius: 3px;
}

.r-slider1 input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #fff;
  margin-top: -5px;
  position: relative;
  z-index: 10000;
}

.r-slider1 input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #555;
  margin-top: -5px;
  position: relative;
  z-index: 10000;
}

.r-slider1 input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #555;
  margin-top: -5px;
  position: relative;
  z-index: 10000;
}

.r-slider1 input[type="range"]:focus {
  outline: none;
}

.r-slider1 .full-range,
.r-slider1 .incl-range {
  width: 100%;
  height: 18px;
  left: 0;
  top: 21px;
  position: absolute;
  background: #ddd;
  border-radius: 10px;
}

.r-slider1 .incl-range {
  background: #f5b659;
}
.r-slider1 h4 {
  font-weight: 400;
}
.r-slider1 h4 span {
  color: #f5b659;
}
.r-slider2 h4 span {
  color: #1d5ebe;
}
.r-slider2 .incl-range {
  background: #239fda;
}

.vessels-listing .key-values {
  background-image: none;
}
.vessels-listing .key-values .key-values-graph-area {
  margin-top: 0;
}
.vessels-listing .key-values h2 {
  margin-bottom: 60px;
}
.result-stats-table .table {
  border: 1px solid #eeeeee;
  margin: 0;
}
.result-stats-table thead {
  background-color: #eeeeee;
}
.result-stats-table thead tr th {
  padding: 25px 8px 25px 20px;
}
.vessels-listing .key-values .row.graphs {
  margin-bottom: 45px;
}
.result-stats-table tbody .flag-content p:nth-child(1) {
  font-weight: bold;
}
.result-stats-table tbody .flag-content p:nth-child(2) {
  font-weight: 400;
}
/* .result-stats-table tbody tr:not(:last-child) {
    margin-bottom: 10px;
} */
.result-stats-table tbody tr {
  background-color: #f7f7fa;
  border-bottom: 5px solid #fff;
}
.result-stats-table tbody tr td {
  padding: 15px 8px 15px 20px;
  vertical-align: middle;
}
.table-pagination p:nth-child(1) {
  font-weight: 400;
  margin-right: 50px;
}
.table-pagination p:nth-child(2) {
  font-weight: 700;
}
.result-stats-table tbody .flag-img {
  width: 65px;
}
.companies-key-values .conerical-ship-img .conerical-img {
  max-width: 300px;
  margin: 0 auto;
}
.comerical-img {
  text-align: end;
}
.conerical-companies .comerical-img {
  max-width: 300px;
  min-height: 250px;
  margin: 0 auto;
}
/* .comerical-img,
.maritime-img {
  width: 100%;
  height: 48%;
  margin: 0 auto;
}
.comerical-img img,
.maritime-img img {
  width: 100%;
  height: 100%;
} */
/* .conerical-ship-img {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 590px;
} */

/**/
/* .nsm7Bb-HzV7m-LgbsSe {
  height: 49px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C6CFD9;
  border-radius: 8px;
} */
.cardWidth {
  background-color: #fff;
  color: #7e92a8;
  border: none;
}
.cardWidth input {
  color: #000;
}
input:focus,
input:focus-visible {
  outline: none !important;
}
.login-text {
  color: #000;
  margin: 0 0 0px;
  font-weight: bold;
  font-size: 42px;
}
.cardWidth a {
  text-decoration: none;
  color: #000;
  font-weight: 900;
  font-size: 20px;
}
.cardWidth .card-body {
  padding: 15px 20px;
  max-width: 700px;
}
.cursor-pointer {
  cursor: pointer;
}
/* input {
  padding: 0 10px !important;
} */
.register-check .form-check input {
  padding: 10px 10px !important;
}
.register-check .form-check label {
  margin-top: 2px;
}
.forget-email {
  width: 350px;
}
.dropdown {
  margin-right: 20px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #0f2f5f;
}
/**/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  caret-color: #000;
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
/**/
.cardWidth input:-webkit-autofill,
.cardWidth input:-webkit-autofill:hover,
.cardWidth input:-webkit-autofill:focus,
.cardWidth textarea:-webkit-autofill,
.cardWidth textarea:-webkit-autofill:hover,
.cardWidth textarea:-webkit-autofill:focus,
.cardWidth select:-webkit-autofill,
.cardWidth select:-webkit-autofill:hover,
.cardWidth select:-webkit-autofill:focus {
  caret-color: #000;
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

/**/
/* .btn-success {
  border: #0f3264;
  background-color: #0f3264;
  color: #fff;
  padding: 7px 15px;
}
.btn-success:hover {
  border: #0f3264;
  background-color: #0f3264;
  color: #fff;
  padding: 7px 15px;
} */

/**/
.hero {
  width: 100%;
  min-height: 75vh;
  background: no-repeat fixed url("../Images/main-bg.png") center / cover; /* from Unsplash */
  margin-top: 50px;

  /* This is what centers it */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero h1 {
  color: #fff;
  font-family: "Museo Sans", Avenir, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 900;
  font-size: 15vmin;
  text-align: center;
  font-size: 15vmin; /* If you want to be extra-fancy */
  line-height: 110%;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-shadow: 0 0.025em 0.05em rgba(0, 0, 0, 0.75),
    0 0.05em 0.1em rgba(0, 0, 0, 0.5);
}
.no-record-text {
  min-height: 200px;
}
.key-flag-area {
  position: relative;
}
.key-flag-area::after {
  content: "";
  background-image: url("../Images/vessel-flag.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 323px;
}
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0px;
  background: transparent !important;
  border: 1px solid #dcdcdc !important;
  color: #0f2f5f;
  border-left: none !important;
}
.header-two-images {
  display: inline-block;
}

/**/
.btn-primary {
  background-color: #1d5ebe;
  color: #fff;
  border: 1px solid #1d5ebe;
  border-radius: 26px;
  padding: 13px 25px;
  white-space: nowrap;
}
.btn-primary:hover {
  border: 1px solid #0f2f5f;
  background-color: #0f2f5f;
  color: #fff;
}

.btn-success {
  background-color: #0f2f5f;
  color: #fff;
  border: 1px solid #0f2f5f;
  border-radius: 39px;
  padding: 10px 25px !important;
  white-space: nowrap;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  border: 1px solid #1d5ebe;
  background-color: #1d5ebe;
  color: #fff;
}

.btn-secondary {
  background-color: #0f2f5f;
  color: #fff;
  border: 1px solid #0f2f5f;
  border-radius: 26px;
  padding: 13px 25px;
  white-space: nowrap;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  border: 1px solid #1d5ebe;
  background-color: #1d5ebe;
  color: #fff;
}

.more.update {
  display: inline-block;
  margin: 0 10px;
}
.more.update button {
  background: transparent;
}

/**/
.add-vesels .label {
  background-color: #f7f7fa;
}
.select-design {
  background: #f7f7fa;
}

/**/
.full-width {
  width: 100%;
}
.btn-width {
  min-width: 148px;
}

/**/
.filter-col {
  padding: 10px 12px;
  border-radius: 4px;
  background: #f7f7fa;
  height: 100%;
}
.filter-col .filter-col-in {
  width: 100%;
}
.filter-buttons .btn-width {
  width: 100%;
  border-radius: 30px;
  padding: 12px 30px;
}
.filter-col h4 {
  font-size: 16px;
}
.filter-col .filter-col-in .form-group h4 {
  margin: 0;
  white-space: nowrap;
}
.filter-col .filter-col-in .form-group {
  margin: 0 0 5px;
}

/**/
.result-stats-table {
  margin: 0 0 30px;
}
.audience-table-section .result-stats-table .table {
  table-layout: fixed;
}
.audience-table-section .result-stats-table .table .td-filters {
  width: 700px;
}
.audience-table-section .result-stats-table .table .td-vessels {
  width: 200px;
}
.audience-table-section .result-stats-table .table .td-companies {
  width: 200px;
}
.audience-table-section .result-stats-table .table .td-contacts {
  width: 200px;
}
.result-table-btn {
  padding: 3px 9px;
  border-radius: 6px;
  font-size: 16px;
  margin: 0 3px;
  background-color: #239fda;
  border: 1px solid #239fda;
}
.audience-graphs .maingraph {
  border: 1px solid #c4c4c4;
  max-width: 100%;
  margin: 0 auto 40px;
  position: relative;
  padding: 15px 0 0;
}
.audience-graphs .maingraph .main-graph-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.audience-graphs .maingraph .main-graph-title span:first-child {
  font-size: 16px;
  font-weight: 700;
  display: block;
  line-height: 24px;
}
.audience-graphs .maingraph .main-graph-title span:last-child {
  font-size: 40px;
  font-weight: 600;
  display: block;
}
.audience-graphs .maingraph h3 {
  font-size: 20px;
}
.graph-holder {
  border-radius: 4px;
  overflow: hidden;
}
.vertical-bar-chart {
  overflow: hidden !important;
}
.vertical-bar-chart .horizontal-chart {
  overflow-x: auto;
  min-width: auto;
  height: calc(100% - 23px);
}
/* .vertical-bar-chart .horizontal-chart-inner {
  min-width: 580px;
} */
.horizontal-chart-inner {
  height: 500px;
  width: 529px;
}

.header-contact-btn {
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  letter-spacing: 1px;
  padding: 7px 15px;
  background: #0f2f5f;
  border: 1px solid #0f2f5f;
  position: fixed;
  top: 269px;
  right: -36px;
  transition: all ease-in-out 0.3s;
  z-index: 10;
  text-decoration: none;
  border-radius: 0 0 5px 5px;
}
.header-contact-btn:hover {
  border-color: #0f2f5f;
  color: #0f2f5f;
  background: #ffffff;
}
.helm-select__menu {
  z-index: 6 !important;
}
.helm-select__option--is-focused,
.helm-select__option--is-selected {
  background: #0f2f5f !important;
  color: #fff !important;
}
.helm-select__control--is-focused {
  border-color: #0f2f5f !important;
  box-shadow: none !important;
}
.helm-select__menu-list {
  border-radius: 4px;
  overflow: hidden;
  padding: 0 !important;
}
.custom-graph-holder {
  height: 600px;
  overflow: auto;
}
.custom-graph-holder::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
  height: 4px;
}
.custom-graph-holder::-webkit-scrollbar-track {
  background: #b9b9b9;
}
.custom-graph-holder::-webkit-scrollbar-thumb {
  background: #123a75;
}
.custom-graph-holder::-webkit-scrollbar-thumb:hover {
  background: #123a75;
}
.custom-graph-holder .echarts-for-react {
  min-width: 1400px;
  height: 550px !important;
}
.custom-graph-holder.less-data .echarts-for-react {
  min-width: 580px;
}
.excess-data {
  min-width: 900px !important;
}
.my-custom-a {
  font-size: 21px !important;
  margin-right: 3px !important;
}
.table-pagination {
  background: #f5f5f6;
}
/* 
/* ================ media quriesssss ================= */

@media (max-width: 1699px) {
  .container-width {
    max-width: 1460px;
  }
  .header-one .header-one-social-icons a {
    margin: 0px 12px;
  }
  .header-one .contact-icons-margin {
    margin-left: 40px;
  }
  .header-three .header-list .nav-item .nav-link {
    padding: 10px 20px;
    min-width: 130px;
    height: 60px;
  }
  .header-three .header-list .nav-link {
    font-size: 16px;
  }
  .header-list .nav-item .icon-holder {
    width: 170px;
  }
  .header-three .header-btn {
    border-radius: 20px;
    height: 42px;
    width: 115px;
    font-size: 16px;
  }
  .header-three .header-btn:hover .user-logo-img {
    left: 75px;
  }
  .header-two-images.d-flex.justify-content-end {
    width: 600px;
    margin-left: auto;
  }
  h1 {
    font-size: 80px;
    line-height: 108px;
  }
  h2 {
    font-size: 50px;
    line-height: 60px;
  }
  h3 {
    font-size: 30px;
  }
  .sea-waves-content h2 {
    margin-bottom: 32px;
  }
  .sea-waves-content p {
    font-size: 20px;
  }
  .sea-waves-content p:nth-child(2) {
    margin-bottom: 32px;
  }
  .sea-waves-info {
    max-width: 550px;
    padding: 30px;
  }
  .sea-waves-img-info p {
    font-size: 20px;
    line-height: 32px;
  }
  .vessels-port h3 {
    margin-bottom: 25px;
  }
  .bg-content-desp p {
    font-size: 20px;
    line-height: 32px;
    padding: 10px 80px;
  }
  .bg-content-desp p span {
    font-size: 28px;
  }
  /* main */
  .main .main-content h1 {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 18px;
  }
  .main .main-content p {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 27px;
  }
  .main .builder-content p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .main .builder-content.content-border::before {
    height: 80px;
    width: 2px;
  }
  /* key values */
  .country-flag-img {
    margin-left: 15px;
    width: 42px;
  }
  /* target market */
  .target-market .target-market-content p,
  .how-it-work .how-it-work-content p {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 55px;
  }
  /* how it work */
  .how-it-work .how-it-work-content p {
    margin-bottom: 22px !important;
  }
}
@media (max-width: 1499px) {
  .container-width {
    max-width: 1320px;
  }
  /* main */
  .main {
    padding-bottom: 280px;
  }
  .main .main-content h4 {
    font-size: 32px;
  }
  .main .main-content h1 {
    font-size: 35px;
    line-height: 44px;
    margin-bottom: 15px;
  }
  .main .main-content p {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
  }
  .main .main-content .btn-transparent {
    height: 53px;
    width: 190px;
    font-size: 18px;
  }
  /* global-coverage */
  .global-coverage {
    margin-top: -280px;
  }
  /* key values */
  .section-padding {
    padding: 70px 0px;
  }
  .key-values .key-values-info-area {
    width: 1188px;
    margin: 0 auto 60px;
  }
  .key-values .key-values-info-area h4,
  .latest-news-info-area h4 {
    font-size: 20px;
    letter-spacing: 4.92px;
  }
  .key-values .key-values-info-area h2,
  .latest-news-info-area h2,
  .how-it-work .how-it-work-content h2 {
    font-size: 48px;
    margin-bottom: 18px;
    line-height: 54px;
  }
  .key-values .images-border-wrapper {
    width: 590px;
    margin: 0 auto 35px;
  }
  .key-values .key-values-info-area p {
    font-size: 21px;
  }
  .key-values .images-border-wrapper::before,
  .key-values .images-border-wrapper::after {
    width: 242px;
  }
  .key-values .key-values-area h3,
  .key-values .key-values-graph-area h3 {
    font-size: 25px;
    margin-bottom: 22px;
  }
  .key-values .key-values-area-content .ul-height {
    height: 269px;
  }
  .country-flag-img {
    margin-left: 13px;
    width: 40px;
  }
  .key-values .key-values-graph-area {
    padding: 33px 0 33px 33px;
  }
  /* target market */
  .target-market .target-market-content p,
  .how-it-work .how-it-work-content p {
    font-size: 21px;
    line-height: 29px;
    margin-bottom: 50px;
  }
  /* latest news */
  .latest-news .latest-news-content-text h4 {
    font-size: 21px;
  }
  /* subscription */
  .subscription .subscription-form input {
    width: 280px;
  }
}
@media (max-width: 1399.98px) {
  /* zoh */
  .sea-waves-bg {
    padding-top: 40px;
  }

  h1 {
    font-size: 70px;
    line-height: 90px;
  }
  h2 {
    font-size: 45px;
    line-height: 60px;
  }
  h3 {
    font-size: 28px;
  }
  .sea-waves-bg {
    padding-top: 50px;
  }
  .sea-waves-content h2 {
    margin-bottom: 22px;
  }
  .sea-waves-content p:nth-child(2) {
    margin-bottom: 22px;
  }
  .sea-waves-content p {
    font-size: 18px;
  }
  .sea-waves-info {
    max-width: 520px;
    padding: 20px;
  }
  .sea-waves-img-info p {
    font-size: 18px;
    line-height: 30px;
  }
  .vessels-port h3 {
    margin-bottom: 20px;
  }
  .ports-container .port .flag-name p,
  .ports-container .port .date p {
    font-size: 16px;
  }
  .vessels-port .accordion-header .accordion-button.collapsed {
    font-size: 16px;
  }
  .bg-content-desp p {
    font-size: 18px;
    line-height: 30px;
  }
  .bg-content-desp p span {
    font-size: 24px;
  }
  .footer-link p {
    font-size: 18px;
  }
  .footer-link-ul a {
    font-size: 16px;
    line-height: 32px;
  }
  .footer .footer-logo {
    margin-bottom: 32px;
  }
  .footer p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 32px;
  }
  .footer .read-more {
    font-size: 15px;
  }
  .copyright p {
    font-size: 15px;
    padding: 12px 0;
  }
  /* ===== vessles listing ======= */
  /* .vessels-database {
    padding: 60px 0 40px 0;
  } */
  .vessels-database .input-fields .form-control,
  .vessels-database .input-fields .dropdown button {
    padding: 16px 18px;
  }
  .vessels-listing .key-values h2 {
    margin-bottom: 45px;
  }
  .header-two-images {
    width: 200px;
  }
  /**/
  /* .key-values .key-values-graph-area {
    height: 578px !important;
  } */
  /**/
  /* .comerical-img,
  .maritime-img {
    width: 100%;
    height: 46%;
  } */
  /* .conerical-ship-img {
    height: 100%;
  } */
  /* .comerical-img img,
  .maritime-img img {
    width: 100%;
    height: 100%;
  } */
  .echarts-for-react {
    /* height: 398px !important; */
  }
  .btn-width {
    min-width: 130px;
  }
  .filter-col {
    height: 578px;
  }
  /**/
  .audience-table-section .result-stats-table .table .td-filters {
    width: 556px;
  }
  .audience-table-section .result-stats-table .table .td-vessels {
    width: 200px;
  }
  .audience-table-section .result-stats-table .table .td-companies {
    width: 200px;
  }
  .audience-table-section .result-stats-table .table .td-contacts {
    width: 200px;
  }
}
@media (max-width: 1399px) {
  .vertical-bar-chart .horizontal-chart {
    height: calc(100% - 32px);
  }
}
@media (max-width: 1366px) {
  .container-width {
    max-width: 1180px;
  }
  .header-one .contact-icons {
    padding-left: 25px;
  }
  .header-one .contact-icons .head-icons {
    width: 20px;
  }
  .header-one .contact-icons h6 {
    font-size: 14px;
  }
  .header-one .contact-icons-margin {
    margin-left: 20px;
  }
  .header-one .header-one-social-icons a {
    margin: 0px 8px;
  }
  .header-one .header-one-social-icons .head-social {
    color: white;
    font-size: 16px;
  }
  .header-two-images.d-flex.justify-content-end {
    width: 500px;
  }
  .header-three .header-list .nav-item .nav-link {
    min-width: 120px;
    height: 55px;
  }
  .header-three .header-list .nav-link {
    font-size: 14px;
  }
  .header-list .nav-item .icon-holder {
    width: 160px;
  }
  .header-three .header-btn {
    border-radius: 20px;
    height: 40px;
    width: 105px;
    font-size: 15px;
    padding-left: 45px;
  }
  .header-three .header-btn:hover .user-logo-img {
    left: 65px;
  }
  /* zoh */
  .sea-waves-bg {
    padding-top: 40px;
  }

  h1 {
    font-size: 60px;
    line-height: 80px;
  }
  h2 {
    font-size: 40px;
    line-height: 50px;
  }
  h3 {
    font-size: 24px;
  }
  .sea-waves-content h2 {
    margin-bottom: 15px;
  }
  .bg-content-desp p {
    padding: 10px 60px;
  }
  .sea-waves-info {
    padding: 30px;
    bottom: -160px;
  }
  .vessels-port h3 {
    margin-bottom: 15px;
  }
  .vessels-database .input-fields .form-control,
  .vessels-database .input-fields .dropdown button {
    padding: 10px 16px;
  }
  /* end z */
  /* main */
  .main {
    padding-bottom: 240px;
  }
  .main .main-content h4 {
    font-size: 30px;
  }
  .main .main-content h1 {
    font-size: 34px;
    line-height: 40px;
  }
  .main .main-content p {
    font-size: 19px;
    line-height: 26px;
  }
  .main .builder-content p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  /* global coverage */
  .global-coverage {
    margin-top: -240px;
  }
  /* key values */
  .section-padding {
    padding: 60px 0px;
  }
  .key-values .key-values-info-area {
    width: 1078px;
    margin: 0 auto 40px;
  }
  .key-values .key-values-info-area h2,
  .latest-news-info-area h2,
  .how-it-work .how-it-work-content h2 {
    font-size: 43px;
    margin-bottom: 10px;
    line-height: 48px;
  }
  .key-values .images-border-wrapper {
    width: 540px;
    margin: 0 auto 27px;
  }
  .key-values .images-border-wrapper::before,
  .key-values .images-border-wrapper::after {
    width: 226px;
  }
  .key-values .key-values-info-area p {
    font-size: 20px;
    line-height: 28px;
  }
  .country-flag-img {
    margin-left: 13px;
    width: 30px;
  }
  .country-graph-wrapper .country-graph-wrapper-info p {
    font-size: 13px;
  }
  /* target market */
  .target-market .target-market-content p,
  .how-it-work .how-it-work-content p {
    font-size: 20px;
    line-height: 29px;
  }
  /* latest news */
  .latest-news .latest-news-content-text h4 {
    font-size: 19px;
    margin-bottom: 14px;
    line-height: 28px;
  }
  .subscription .subscription-form input {
    width: 230px;
  }
  .vessels-listing .key-values .row.graphs {
    margin-bottom: 70px;
  }
  .sea-small-images iframe {
    max-width: 100% !important;
  }
}
@media (max-width: 1199px) {
  .container-width {
    max-width: 960px;
  }
  .header-two-images {
    width: 200px;
  }
  .header-two-images.d-flex.justify-content-end {
    width: 450px;
  }
  .header-three .header-list .nav-item .nav-link {
    min-width: 100px;
    height: 50px;
    padding: 8px 15px;
  }
  .header-three .user-logo-img {
    height: 28px;
    width: 28px;
  }
  /* main */
  .main {
    padding-bottom: 200px;
  }
  .main .main-content h4 {
    font-size: 27px;
  }
  .main .main-content h1 {
    font-size: 34px;
    line-height: 40px;
  }
  /* global coverage */
  .global-coverage {
    margin-top: -190px;
  }
  /* key values */
  .key-values .key-values-info-area {
    width: 860px;
    margin: 0 auto 35px;
  }
  .key-values .key-values-info-area h2,
  .latest-news-info-area h2,
  .how-it-work .how-it-work-content h2 {
    font-size: 40px;
    margin-bottom: 10px;
    line-height: 45px;
  }
  .key-values .images-border-wrapper {
    width: 400px;
    margin: 0 auto 25px;
  }
  .key-values .images-border-wrapper::before,
  .key-values .images-border-wrapper::after {
    width: 156px;
  }
  .key-values .key-values-info-area p {
    font-size: 19px;
  }
  .key-values .key-values-area-content .ul-height {
    height: 288px;
  }
  .key-values .key-values-graph-area {
    margin-top: 52px;
    padding: 33px 0 33px 33px;
  }
  .key-values .key-values-graph-area ul li {
    font-size: 16px;
    padding-left: 21px;
  }
  /* .key-values .key-values-graph-area {
    padding: 40px;
  } */
  .key-values .key-values-graph-area {
    max-width: 650px;
    width: 100%;
    margin: 52px auto 0px;
  }
  .country-flag-img {
    margin-left: 15px;
    width: 46px;
  }
  .country-graph-wrapper .country-graph-wrapper-info p {
    font-size: 16px;
  }
  /* target market */
  .target-market .target-market-content h3,
  .how-it-work .how-it-work-content h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 27px;
  }
  .target-market .target-market-content p,
  .how-it-work .how-it-work-content p {
    margin-bottom: 35px;
  }
  .target-market ul li {
    font-size: 17px;
    line-height: 29px;
  }
  /* latest news */
  .latest-news .latest-news-content-text {
    padding-right: 30px;
  }
  /* subscription */
  .subscription .subscription-area {
    flex-direction: column;
    text-align: center;
    padding: 160px 40px 40px 40px;
  }
  .subscription .subscription-area .subscription-area-img {
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
  }
  .subscription .subscription-area-content {
    margin-bottom: 30px;
  }
  .subscription .subscription-form {
    margin-bottom: 30px !important;
  }
  .subscription .subscription-form input {
    width: 250px;
    height: 55px;
  }
  /* zoh */
  .sea-waves-info {
    bottom: -110px;
  }
  .vessels-port {
    padding-top: 150px;
  }
  .sea-img {
    height: 100%;
  }
  /* end z */
  .vessels-port .marginb {
    margin-bottom: 40px;
  }
  .footer {
    padding-top: 40px;
  }
  .copyright {
    margin-top: 20px;
  }

  .footer p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  .footer-link p {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .footer-link-ul a {
    font-size: 14px;
    line-height: 28px;
  }
  .copyright p {
    font-size: 14px;
    padding: 10px 0;
  }
  .copyright ul a {
    font-size: 14px;
  }
  h4 {
    font-size: 20px;
  }
  .vessels-listing .key-values .row.graphs {
    margin-bottom: 60px;
  }
  /**/
  table.table.table-striped.custom-table {
    width: 1050px;
  }
  /**/
  /* .conerical-ship-img {
    max-width: 350px;
    margin: 0 auto;
  } */
  /**/
  .country-graph-wrapper-info {
    width: 40% !important;
  }
  .progress-wrapper {
    width: 70% !important;
  }
  /**/
  .filter-col {
    background: transparent;
  }

  .filter-col .filter-col-in {
    width: 100%;
    padding: 12px;
    height: auto;
  }
  .filter-col {
    height: 100%;
  }
  .filter-image-holder {
    background: #f7f7fa;
    padding: 0 0 10px;
  }
}
@media (max-width: 991px) {
  .container-width {
    max-width: 720px;
  }
  .header-one-social-icons {
    display: none !important;
  }
  .header-two-images {
    width: 160px;
    margin-left: 50px;
  }
  .header-two-images.d-flex.justify-content-end {
    display: none !important;
  }
  .header-buttons {
    padding: 10px;
  }
  .header-three .navbar-toggler {
    position: absolute;
    top: -55px;
    border: none;
    padding: 0;
    color: #0f2f5f;
    box-shadow: none;
    outline: none;
  }

  .header-two {
    padding: 10px 0px;
  }
  .header-three .navbar-collapse {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #0f3264;
    z-index: 2;
  }
  .header-three .navbar-collapse ul {
    margin: 0 !important;
  }
  .header-list .nav-item .icon-holder {
    display: none !important;
  }
  .header-three .header-list .nav-item .nav-link {
    justify-content: flex-start;
  }
  /* main */
  .main {
    padding-bottom: 170px;
    height: auto;
  }
  .main .main-content {
    padding: 50px 0px;
  }
  .main .main-content-input-wrapper,
  .table-section .table-section-area-form-content .main-content-input-wrapper {
    width: 100%;
  }
  .main .row-padding {
    padding-left: 0px;
  }
  .builder-col-info .content-padding {
    padding-left: 35px;
  }
  .content-padding {
    padding-left: 0px;
  }
  /* global coverage */
  .global-coverage {
    margin-top: -140px;
  }
  /* key values */
  .key-values .key-values-info-area {
    width: 680px;
    margin: 0 auto 30px;
  }
  .key-values .key-values-info-area h2,
  .latest-news-info-area h2,
  .how-it-work .how-it-work-content h2 {
    font-size: 38px;
    margin-bottom: 10px;
    line-height: 41px;
  }
  .key-values-area {
    margin-bottom: 30px;
  }
  /* updated css */
  .vesseles-last-info-content p {
    font-size: 18px;
    line-height: 26px;
  }
  /* ======== vessels listing ==== */
  .r-slider1 h4 {
    text-align: left !important;
    padding-top: 10px;
  }
  .vessels-database h2 {
    margin-bottom: 30px;
  }
  .vessels-database .input-fields {
    margin-bottom: 40px;
  }
  /**/
  .update-page .select {
    margin: 0 0 15px;
  }
  .company-contact input {
    margin: 0 0 15px;
  }
  .company-contact .inline-flex {
    margin: 0 0 0px !important;
  }
  .company-contact .select {
    margin: 0 0 15px;
  }
  .vessels-database {
    padding: 4px 0;
  }
}
@media (max-width: 767.98px) {
  /* zoh */
  .banner-heading h1 {
    font-size: 40px;
    line-height: 58px;
  }
  h2 {
    font-size: 32px;
    line-height: 38px;
  }
  .sea-waves-info {
    bottom: -140px;
  }
  .accordion-container {
    padding: 10px 14px;
  }
  .sea-img {
    width: 100%;
  }
  .bg-content-desp {
    margin-top: 10px;
  }
  .bg-content-desp p {
    padding: 10px 30px;
  }
  .bg-content-desp p span {
    font-size: 26px;
  }
  /* end z */
  /* main */
  .main {
    padding-bottom: 80px;
  }
  .main .main-content {
    padding: 60px 0px;
    text-align: center;
  }
  .main .main-content-input-wrapper,
  .table-section .table-section-area-form-content .main-content-input-wrapper {
    margin-bottom: 30px;
  }
  .main .builder-content p {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .builder-row {
    margin-bottom: 30px;
  }
  /* global coverage */
  .global-coverage {
    margin-top: -80px;
  }
  /* key values */
  .section-padding {
    padding: 50px 0px;
  }
  .key-values .key-values-info-area {
    width: 490px;
  }
  .key-values .key-values-info-area h2,
  .latest-news-info-area h2,
  .how-it-work .how-it-work-content h2 {
    font-size: 34px;
    line-height: 41px;
  }
  .key-values .key-values-area h3,
  .key-values .key-values-graph-area h3 {
    font-size: 24px;
  }
  /* table section */
  .table-section .table-section-area {
    padding: 33px;
  }
  .table-section .table-section-area-form-content {
    margin-bottom: 10px;
    flex-flow: column;
  }
  .subscription .subscription-form input {
    height: 50px;
  }
  .banner .banner-content .banner-img {
    height: 150px;
  }
  .banner .banner-content .banner-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  /* updated css */
  .sea-small-images {
    width: 60%;
    margin: 0 auto;
  }
  .vessels-database .input-fields .col-md-3 h4 {
    padding-top: 10px;
  }
  .vessels-listing .key-values .row.graphs {
    margin-bottom: 50px;
  }
  h4 {
    font-size: 19px;
  }
  /* .vessels-database {
    padding: 50px 0 40px 0;
  } */
  /**/
  .btn-success {
    font-size: 14px;
  }
  .btn-secondary {
    font-size: 14px;
  }
  .btn-primary {
    font-size: 14px;
  }
  .footer::before {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .banner-heading h1 {
    font-size: 25px;
    line-height: 40px;
  }
  h2 {
    font-size: 23px;
    line-height: 30px;
  }
  h3 {
    font-size: 21px;
  }
  .sea-waves-bg {
    padding-top: 20px;
  }
  .vessels-port h3 {
    margin-bottom: 10px;
  }
  .sea-waves-content p {
    font-size: 16px;
    line-height: 24px;
  }
  .sea-waves-content p:nth-child(2) {
    margin-bottom: 10px;
  }
  .sea-waves-img-info p {
    font-size: 16px;
    line-height: 24px;
  }
  .sea-waves-info {
    position: unset;
  }
  .vessels-port {
    padding-top: 20px;
  }
  .vessels-port .accordion-header .accordion-button.collapsed {
    font-size: 16px;
  }
  .vessels-port .accordion-table .row1 {
    flex-direction: column;
  }
  .accordion-container {
    height: unset;
  }
  .ports-container .port {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .img-margin-b {
    margin-bottom: 10px;
  }
  .ports-container .port .date p,
  .ports-container .port .flag-name p {
    font-size: 16px;
  }
  .bg-content-desp p {
    padding: 10px;
  }
  .bg-content-desp p {
    font-size: 16px;
    line-height: 24px;
  }
  .bg-content-desp p span {
    font-size: 18px;
  }
  .footer {
    padding-top: 20px;
  }
  .footer .footer-logo {
    margin-bottom: 20px;
  }
  .footer p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .footer-links-container {
    padding-top: 30px;
  }
  .footer-link-ul a {
    font-size: 16px;
    line-height: 24px;
  }
  .footer-link.contact-us {
    margin-top: 30px;
  }
  .copyright .main-copyright-div {
    flex-direction: column;
  }
  .copyright p {
    padding: 7px 0;
  }
  .copyright ul a {
    font-size: 16px;
  }

  /* main */
  .main {
    height: auto;
  }
  .main .main-content {
    padding: 40px 0px;
  }
  .main .main-content h4 {
    font-size: 26px;
  }
  .main .main-content h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .main .builder-col-head {
    width: 100%;
  }
  .main .builder-col-info {
    width: 100%;
  }
  .builder-row {
    margin-bottom: 20px;
    flex-direction: column;
  }
  .builder-col-info .content-padding {
    padding-left: 0px;
  }
  .main .builder-content.content-border::before {
    display: none;
  }
  /* global coverage */
  .global-coverage {
    margin-top: -60px;
  }
  /* key values */
  .key-values .key-values-info-area {
    width: 270px;
  }
  .key-values .images-border-wrapper {
    width: 100%;
  }
  .key-values .images-border-wrapper::before,
  .key-values .images-border-wrapper::after {
    width: 100px;
  }
  .key-values .key-values-info-area h2,
  .latest-news-info-area h2,
  .how-it-work .how-it-work-content h2 {
    font-size: 32px;
    line-height: 39px;
  }
  .key-values .key-values-info-area h4,
  .latest-news-info-area h4 {
    font-size: 19px;
    letter-spacing: 3.92px;
  }
  .key-values .key-values-area h3,
  .key-values .key-values-graph-area h3 {
    font-size: 22px;
  }
  .country-graph-wrapper .progress-wrapper-bg p {
    font-size: 12px;
  }
  .key-values .key-values-graph-area {
    padding: 15px 0 15px 30px;
  }
  .country-flag-img {
    margin-left: 10px;
    width: 35px;
  }
  .country-graph-wrapper .country-graph-wrapper-info p {
    font-size: 12px;
  }
  /* target market */
  .target-market .target-market-content h3,
  .how-it-work .how-it-work-content h3 {
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 16px;
  }
  .target-market .target-market-content p,
  .how-it-work .how-it-work-content p {
    font-size: 19px;
    line-height: 27px;
  }
  .how-it-work .how-it-work-content h3 {
    padding-right: 0px;
  }
  /* latest news */
  .latest-news .latest-news-content-text h4 {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 26px;
  }
  /* table section */
  .subscription .subscription-form {
    flex-direction: column;
  }
  .table-section .table-section-area {
    padding: 5px;
  }
  .main .main-content-input-wrapper input::placeholder,
  .table-section
    .table-section-area-form-content
    .main-content-input-wrapper
    input::placeholder {
    font-size: 10px;
  }
  .main .main-content-input-wrapper .btn-blue,
  .table-section
    .table-section-area-form-content
    .main-content-input-wrapper
    .btn-blue {
    width: 75px;
    font-size: 14px;
  }
  .main .main-content-input-wrapper .search-icon,
  .table-section
    .table-section-area-form-content
    .main-content-input-wrapper
    .search-icon {
    height: 40px;
  }
  .main .main-content-input-wrapper,
  .table-section .table-section-area-form-content .main-content-input-wrapper {
    height: 50px;
  }
  /* zoh */
  .sea-waves-content {
    padding-top: 0;
  }
  .sea-waves-content h2 {
    margin-bottom: 10px;
  }
  .sea-waves-content p:nth-child(2) {
    margin-bottom: 20px;
  }

  .sea-waves-info {
    padding: 20px;
  }

  .sea-waves-info {
    bottom: -130px;
  }
  .vessels-port .col-lg-5 {
    margin-top: 30px;
  }

  /* end z */
  .accordion-container {
    height: auto;
  }
  .footer-links-container {
    padding-top: 30px;
  }
  .copyright p {
    font-size: 13px;
  }
  .key-values .key-values-area-content {
    padding: 12px;
  }
  .key-values .key-values-area-content ul li {
    min-width: 300px;
  }
  /* updated css */
  .sea-small-images {
    width: 100%;
    margin: 0 auto;
  }
  .content-table-bg {
    padding: 15px !important;
  }
  /* ============ vessels listing ========= */
  .vessels-database .input-fields .form-control,
  .vessels-database .input-fields .dropdown button {
    padding: 14px 16px;
  }
  .vessels-listing .key-values h2 {
    margin-bottom: 20px;
  }
  .vessels-listing .key-values .row.graphs {
    margin-bottom: 40px;
  }
  .table-pagination p:nth-child(1) {
    margin-right: 29px;
  }
  .r-slider1 .full-range,
  .r-slider1 .incl-range {
    height: 14px;
    top: 18px;
  }
  .r-slider1 input[type="range"]::-webkit-slider-thumb {
    height: 25px;
    width: 25px;
  }
  h4 {
    font-size: 17px;
  }
  span.text-danger {
    font-size: 14px;
  }
  /**/
  .header-three .navbar-toggler {
    top: -46px;
  }
  .comerical-img,
  .maritime-img {
    text-align: center;
  }
}

@media (max-width: 479.98px) {
  .forget-email {
    width: 100%;
  }
  .centerAlign {
    font-size: 15px;
  }
  .register-check .form-check label {
    font-size: 12px;
  }
  .select-design {
    font-size: 11px !important;
  }
  /**/
  .btn-success {
    font-size: 13px;
    padding: 12px 25px;
  }
  .btn-secondary {
    font-size: 13px;
    padding: 12px 25px;
  }
  .btn-primary {
    font-size: 13px;
    padding: 12px 25px;
  }
  /**/
  .btn-width {
    min-width: 130px;
  }
  .slider__track,
  .slider__range {
    height: 13px !important;
  }
}

@media (max-width: 479.98px) {
  .company-contact {
    padding: 0;
  }
  /* .comerical-img,
  .maritime-img {
    width: 100%;
    height: 225px;
  } */
  .country-flag-img {
    margin-left: 10px !important;
    width: 35px !important;
  }
  .key-values .key-values-graph-area {
    padding: 15px 0 15px 15px;
  }
  .country-graph-wrapper-info {
    width: 53% !important;
  }
  .progress-wrapper {
    width: 47% !important;
  }
  .country-flag-img {
    margin-left: 30px;
    width: 30px;
    margin-right: 15px;
  }
  .filter-buttons .btn-width {
    min-width: 100%;
  }
  .filter-buttons .btn-success {
    background-color: #1d5ebe;
  }
}

@media (max-width: 319.98px) {
  .country-graph-wrapper-info {
    width: 53% !important;
  }
  .progress-wrapper {
    width: 47% !important;
  }
  .select-design {
    font-size: 7px !important;
  }
}

/* //////////////////////////////////////////////////////DropDownStyle//////////////////////////////////////////// */

.select-design {
  /* appearance: none; */
  outline: 0;
  box-shadow: none;
  border: 1px solid #dcdcdc !important;
  background: transparent;
  background-image: none;
  flex: 1 1;
  padding: 0 0.5em;
  color: #000;
  cursor: pointer;
  font-size: 0.8em;
  font-family: "Open Sans", sans-serif;
  position: relative;
}
.select-design::-ms-expand {
  display: none;
}
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  line-height: 3;
  /* background: #5c6664; */
  overflow: hidden;
  border-radius: 0.25em;
  margin: 0 0 0px;
  padding: 0;
}
.company-contact .select {
  margin: 0 0 0 !important;
}
.company-contact .effect-16 {
  margin: 0 0 0 !important;
}
.select::after {
  content: "\25BC";
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 0 1em;
  background: transparent;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
  border: none !important;
  height: 22px;
  display: none;
}
.select:hover::after {
  color: #23b499;
}
.signup-middle-align {
  margin-top: 0% !important;
}
.navbar-nav-about {
  align-items: flex-end;
}
.navbar-nav-about li a {
  color: #fff;
  font-size: 18px;
}
/**/
#dropdown-basic {
  background-color: transparent;
  border: none;
  padding: 0;
}
#dropdown-basic:focus {
  box-shadow: none;
  outline: none;
}
.dropdown-toggle::after {
  margin-left: 0.6em;
}
.dropdown-menu.show {
  transform: translate3d(-85px, 32px, 0px) !important;
}

/* ///////////////////Input number styling  ///////////////////////////////  */
/* .number-class{
    width: 75%;
    margin-top: 2%;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 2px solid #ccc;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
} */
/**/
.choose-file-section {
  position: relative;
}
.choose-file-section:hover .choose-tag {
  color: #0f2f5f;
  background-color: #fff;
  border-color: #0f2f5f;
}
.choose-tag {
  background-color: #0f2f5f;
  border-color: #0f2f5f;
  padding: 10px;
  color: #fff;
}
.choose-tag:hover {
  background-color: #0f2f5f;
  border-color: #0f2f5f;
  padding: 10px;
  color: #fff;
}
.choose-file-section input {
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  bottom: -12px;
  opacity: 0;
}
/**/
.profile-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto 30px;
  overflow: hidden;
  border: 1px solid #0f2f5f;
}
/**/
.loader-bg {
  min-height: 100vh;
  font-family: Roboto, Arial;
  color: #adafb6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 251, 255, 0.6);
}
.text-color {
  color: black !important;
}
.result-stats-table tr {
  position: relative;
}
.link-styling {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 99;
}
.ul-color {
  color: #ffd046;
}
.boxes {
  height: 32px;
  width: 32px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  margin-top: 32px;
  -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}
.boxes .box {
  width: 32px;
  height: 32px;
  top: 0px;
  left: 0;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-animation: box1 1s linear infinite;
  animation: box1 1s linear infinite;
}
.boxes .box:nth-child(2) {
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-animation: box2 1s linear infinite;
  animation: box2 1s linear infinite;
}
.boxes .box:nth-child(3) {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
  -webkit-animation: box3 1s linear infinite;
  animation: box3 1s linear infinite;
}
.boxes .box:nth-child(4) {
  -webkit-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-animation: box4 1s linear infinite;
  animation: box4 1s linear infinite;
}

.boxes .box > div {
  background: #5c8df6;
  --translateZ: 15.5px;
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #5c8df6;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
  top: 0;
  left: 0;
  background: #5c8df6;
}
.boxes .box > div:nth-child(2) {
  background: #145af2;
  right: 0;
  --rotateY: 90deg;
}
.boxes .box > div:nth-child(3) {
  background: #447cf5;
  --rotateX: -90deg;
}
.boxes .box > div:nth-child(4) {
  background: #dbe3f4;
  top: 0;
  left: 0;
  --translateZ: -90px;
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }
  50% {
    transform: translate(200%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}

.visiting-home-link {
  cursor: pointer !important;
}

.hide-y-scroll {
  overflow: hidden !important;
}
.search-blur {
  filter: blur(4px);
  user-select: none;
}
/******* main loader ********/
.main-loader {
  width: 100%;
  height: 100vh;
}
/* common */
.loading {
  font-size: 84px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-align: center;
  color: #0f3264 !important;
}
.loading span {
  display: inline-block;
  margin: 0 -0.05em;
  margin: 0px 5px;
}
/**/
.loading04 span {
  animation: loading04 0.9s infinite;
}
.loading04 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading04 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading04 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading04 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading04 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading04 span:nth-child(7) {
  animation-delay: 0.6s;
}
.loading04 span:nth-child(8) {
  animation-delay: 0.7s;
}
.loading04 span:nth-child(9) {
  animation-delay: 0.8s;
}
@keyframes loading04 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
}

/*loader responsive*/
@media (max-width: 991.98px) {
  .loading {
    font-size: 65px;
  }
}
@media (max-width: 767.98px) {
  .loading {
    font-size: 55px;
  }
}
@media (max-width: 575.98px) {
  .loading {
    font-size: 45px;
  }
}
@media (max-width: 479.98px) {
  .loading {
    font-size: 30px;
  }
}
@media (max-width: 319.98px) {
  .loading span {
    margin: 0px 3px;
  }
}
.loader-audience {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  height: 100%;
  pointer-events: none;
}
.maingraph h4 {
  top: 50%;
  left: 50%;
  position: absolute;
  color: #0f2f5f;
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
.ChartWidth {
  height: 450px;
}
.propInfo {
  background-color: #f7f7fa;
  padding: 15px 10px 15px 6px;
  margin: 0 15px 15px 0;
  border: 1px solid #f7f7fa;
}
.company-flags {
  width: 94px;
}
.delIcon {
  width: 120px;
}
.delIconSibling {
  width: calc(100% - 130px);
}
.customMultiSelect svg {
  color: black;
}
